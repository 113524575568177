import { useTranslate } from '/machinery/I18n'
import { HeadingFormattedMd } from '/features/buildingBlocks/HeadingFormatted'
import { HighlightStripe } from '/features/buildingBlocks/Highlight'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { useTheme } from '/machinery/Theme'
import star from '/images/star.png'
import styles from './Questionnaire.css'

export function Questionnaire({ antwoorden, title, onAntwoord, onComplete }) {
  const { __ } = useTranslate()
  const theme = useTheme()
  const [step, setStep] = React.useState('question')
  const [antwoordId, setAntwoordId] = React.useState(null)
  const antwoord = antwoorden.find(x => x._key === antwoordId)

  return (
    <div className={cx(styles.component, theme.color)}>
      <div className={styles.content}>
        {step === 'question' && (
          <div className={styles.inner}>
            <Vraag layoutClassName={styles.question} titleBlock={title} />
            <Antwoorden
              layoutClassName={styles.answers}
              onAnswer={handleAntwoord}
              answers={antwoorden}
            />
            <ButtonPrimary layoutClassName={styles.skip} onClick={onComplete} dataX='skip-question'>
              {__`skip-vraag`}
            </ButtonPrimary>
          </div>
        )}

        {step === 'feedback' && (
          <Feedback text={antwoord.feedback} onDismiss={onComplete} />
        )}
      </div>
    </div>
  )

  function handleAntwoord(antwoordId) {
    setStep('feedback')
    setAntwoordId(antwoordId)
    onAntwoord(antwoorden.find(x => x._key === antwoordId).text)
  }
}

function Vraag({ titleBlock, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentVraag, layoutClassName)}>
      <HeadingFormattedMd
        value={titleBlock}
        h={3}
        highlightComponent={HighlightStripe}
      />
    </div>
  )
}

function Antwoorden({ answers, onAnswer, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentAntwoorden, layoutClassName)}>
      {answers.map(({ text, _key }) => (
        <Answer onClick={() => onAnswer(_key)} key={_key}>
          {text}
        </Answer>
      ))}
    </div>
  )
}

function Answer({ onClick, children }) {
  return (
    <button type='button' {...{ onClick }} className={styles.componentAnswer} data-x='questionnaire-answer'>{children}</button>
  )
}

function Feedback({ text, onDismiss }) {
  const { __ } = useTranslate()
  return (
    <div className={styles.componentFeedback}>
      <img className={styles.star} src={star} alt='' />
      <p className={styles.feedBackText}>{text}</p>
      <ButtonPrimary onClick={onDismiss} dataX='resume-video'>{__`verder-kijken`}</ButtonPrimary>
    </div>
  )
}
