import { useTransition, animated } from 'react-spring'
import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useMediaQuery'

import { InfoBlock } from '/features/home/pageOnly/InfoBlock'

import { ButtonPlay } from '/features/buildingBlocks/ButtonPlay'
import { Modal } from '/features/buildingBlocks/Modal'
import { TextPrimaryMd } from '/features/buildingBlocks/Text'
import { VideoPlayer, useVideoTracking } from '/features/buildingBlocks/VideoPlayer'
import { Image } from '/features/buildingBlocks/Image'
import { HeadingFormattedLg } from '/features/buildingBlocks/HeadingFormatted'
import { HighlightStripe } from '/features/buildingBlocks/Highlight'
import { Questionnaire } from '/features/buildingBlocks/Questionnaire'

import arrows from '/images/arrows.png'

import mediaStyles from '/cssGlobal/media.css'
import styles from './NotarissenAanHetWoord.css'

const { viewportSm } = mediaStyles

export function NotarissenAanHetWoord({ interactieveVideos, titleBlocks }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <HeadingFormattedLg
          h={2}
          value={titleBlocks}
          highlightComponent={HighlightStripe}
        />
      </div>
      <div className={styles.videos}>
        <TextPrimaryMd>
          <p>{__`in-vijf-interactieve-videos-doen-notarissen-hun-verhaal`}</p>
        </TextPrimaryMd>
        {interactieveVideos.map(x =>
          <Video
            key={x._key}
            layoutClassName={cx(styles.video, styles.video)}
            titel={x.titel}
            vraag={x.vraag}
            antwoorden={x.antwoorden}
            thumbnail={x.thumbnail}
            videoDesktop={x.videoDesktop}
            videoMobiel={x.videoMobiel}
            timestamp={x.timestamp}
          />
        )}
      </div>
      <div className={styles.containerText}>
        <InfoBlock
          buttonHref='https://www.linkedin.com/groups/9157022/'
          buttonTitle={__`bekijk-onze-linkedin`}
          text={__`meer-weten-over-het-vak-notaris`}
        />
      </div>
    </div>
  )
}

function Video({
  titel,
  thumbnail,
  vraag,
  antwoorden,
  timestamp,
  videoDesktop,
  videoMobiel,
  layoutClassName,
}) {
  const [hover, setHover] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(false)

  return (
    <div
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      className={cx(styles.componentVideo, layoutClassName)}
    >
      <div className={cx(styles.button, styles.relativeToParent)}>
        <ButtonPlay onClick={() => setFullScreen(true)} relativeToParent text={titel} />
      </div>

      <Image
        layoutClassName={cx(styles.image, hover && styles.isHover)}
        image={thumbnail}
        imgProps={{ loading: 'lazy' }}
      />

      <img className={cx(styles.arrows, hover && styles.isHover)} alt="" src={arrows} width='116' height='95' />

      <VideoModal
        name={titel}
        onModalClose={() => setFullScreen(false)}
        {...{ fullScreen, timestamp, videoDesktop, videoMobiel, vraag, antwoorden }}
      />
    </div>
  )
}

function VideoModal({
  name,
  onModalClose,
  fullScreen,
  videoDesktop,
  videoMobiel,
  vraag,
  antwoorden,
  timestamp,
}) {
  const videoRef = React.useRef(null)
  const [playing, setPlaying] = React.useState(true)
  const { eventHandlers, trackEvent } = useVideoTracking({ name, type: 'questionnaire' })
  const [questionnaireState, setQuestionnaireState] =
    useQuestionairStateWithTracking({ initialState: 'waiting', trackEvent })

  const isAtLeastViewportSm = useMediaQuery(viewportSm)
  const isBelowViewportSm = useMediaQuery(`not ${viewportSm}`)

  const url = (
    isAtLeastViewportSm ? videoDesktop :
    isBelowViewportSm ? videoMobiel :
    null
  )

  React.useEffect(
    () => {
      setQuestionnaireState('waiting')
      setPlaying(true)
      videoRef.current && videoRef.current.seekTo(0)
    },
    [fullScreen, setQuestionnaireState]
  )

  const transitions = useTransition(questionnaireState === 'showing', {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <Modal onClose={onModalClose} visible={fullScreen}>
      <div className={styles.componentVideoModal}>
        <VideoPlayer
          onProgressChange={handleProgress}
          playing={fullScreen && playing && questionnaireState !== 'showing'}
          onSeek={handleSeek}
          {...{ eventHandlers, url, videoRef }}
        />

        {transitions((style, visible) => visible && (
          <animated.div className={styles.questionnaire} {...{ style }}>
            <Questionnaire
              title={vraag}
              onComplete={handleComplete}
              onAntwoord={handleAntwoord}
              {...{ antwoorden }}
            />
          </animated.div>
        ))}
      </div>
    </Modal>
  )

  function handleProgress(progress) {
    if (questionnaireState === 'waiting' && progress > timestamp) {
      setQuestionnaireState('showing')
    }

    if (progress < timestamp) setQuestionnaireState('waiting')
  }

  function handleComplete() {
    setQuestionnaireState('dismissed')
    setPlaying(true)
  }

  function handleSeek(seconds) {
    if (seconds > timestamp) setQuestionnaireState('dismissed')
  }

  function handleAntwoord(answer) {
    trackEvent(`questionaire_answer`, { answer })
  }
}

function useQuestionairStateWithTracking({ initialState, trackEvent }) {
  const [state, setState] = React.useState(initialState)
  return [
    state,
    React.useCallback(
      state => setState(x => {
        if (x !== state) trackEvent(`questionaire_${state}`)
        return state
      }),
      [trackEvent]
    )
  ]
}
