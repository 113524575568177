import { useTheme } from '/machinery/Theme'

import styles from './Button.css'

export function ButtonPrimary({
  onClick = undefined,
  children,
  type = 'button',
  layoutClassName = undefined,
  dataX,
}) {
  const theme = useTheme()
  return (
    <ButtonBase
      className={cx(styles.componentPrimary, theme.primary, theme.onPrimary, layoutClassName)}
      {...{ onClick, type, children, dataX }}
    />
  )
}

export function ButtonCircularPrimary({
  onClick = undefined,
  children,
  type = 'button',
  layoutClassName = undefined,
  dataX,
}) {
  const theme = useTheme()
  return (
    <ButtonBase
      className={cx(styles.componentCircularPrimary, theme.primary, theme.onPrimary, layoutClassName)}
      {...{ onClick, type, children, dataX }}
    />
  )
}

export function ButtonGhost({
  onClick = undefined,
  children,
  type = 'button',
  layoutClassName = undefined,
  dataX,
}) {
  const theme = useTheme()
  return (
    <ButtonBase
      className={cx(styles.componentGhost, theme.border, layoutClassName)}
      {...{ onClick, type, children, dataX }}
    />
  )
}

export function ButtonCircularGhost({
  onClick = undefined,
  children,
  type = 'button',
  layoutClassName = undefined,
  dataX,
}) {
  const theme = useTheme()
  return (
    <ButtonBase
      className={cx(styles.componentCircularGhost, theme.border, layoutClassName)}
      {...{ onClick, type, children, dataX }}
    />
  )
}

export function ButtonLink({
  onClick = undefined,
  children,
  type = 'button',
  layoutClassName = undefined,
  dataX,
}) {
  return (
    <ButtonBase
      className={cx(styles.componentLink, layoutClassName)}
      {...{ onClick, type, children, dataX }}
    />
  )
}

function ButtonBase({ className, onClick, type, children, dataX }) {
  return (
    <button className={cx(styles.componentBase, className)} {...{ onClick, type, children }} data-x={dataX}>
      {children}
    </button>
  )
}
